@font-face {
  font-family: 'HarmonyOS Sans';
  src: url('./assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Medium.ttf');
}

@font-face {
  font-family: 'HarmonyOS Heading';
  font-weight: 700;
  src: url('./assets/font/HarmonyOS_Sans/HarmonyOS_Sans_Medium.ttf');
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}
