@for $size from 10 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem !important;
  }
}
$weights: 100 200 300 400 500 600 700 800;
@each $weight in $weights {
  .fw--#{$weight} {
    font-weight: $weight !important;
  }
}
